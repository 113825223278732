import { parseISO } from 'date-fns';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { useEffect, useMemo, useRef } from 'react';

import AspectRatio from '@/components/AspectRatio';
import buildImageHoverTitle from '@/cutils/builders/buildImageHoverTitle';
import { MediaContextPlaybackStatus } from '@/cutils/context/MediaContext';
import { useMediaState } from '@/cutils/hooks/useMediaContext';
import getIsMediaOffline from '@/cutils/media/getIsMediaOffline';
import preventDefault from '@/cutils/misc/preventDefault';
import buildMediaStructuredData from '@/cutils/seo/schema-org/buildModuleMediaStructuredData';
import { MediaControlButtonVariant } from '@/types/enums';
import { resize } from '@/utils/image/resize';

import styles from './MediaAudioVideo.module.scss';
import { MediaOverlay } from './Shared/MediaOverlay';

type Props = {
	/**
	 * The variant of the media control button.
	 */
	buttonVariant: MediaControlButtonVariant;
	/**
	 * The copyright information for the media content.
	 */
	copyright?: string | null;
	/**
	 * The duration of the media content.
	 */
	duration: number | null;
	/**
	 * Additional meta data for the media content.
	 */
	meta?: string;
	/**
	 * The preload setting for the media player.
	 */
	preload?: string;
	/**
	 * The publication date of the media content.
	 */
	publicationDate?: string | null;
	/**
	 * The alt text for the thumbnail image.
	 */
	thumbnailAltText?: string | null;
	/**
	 * The copyright information for the thumbnail image.
	 */
	thumbnailCopyright?: string | null;
	/**
	 * The link for the thumbnail image.
	 */
	thumbnailLink?: string | null;
	/**
	 * The URL of the thumbnail image.
	 */
	thumbnailUrl?: string | null;
	/**
	 * The title of the media content.
	 */
	title?: string | null;
	/**
	 * The URL of the media content.
	 */
	url: string;
	/**
	 * The title of the media content.
	 */
	customTitle?: string | null;
	/**
	 * Indicates whether to show the title of the media content.
	 */
	showTitle?: boolean;
	/**
	 * Indicates whether the media content is a priority.
	 */
	isPriority?: boolean;
	/**
	 * Indicates whether the media content is a hero.
	 */
	isHero?: boolean;
	/**
	 * Callback function to handle changes in the play status.
	 */
	onPlayStatusChange?(status: MediaContextPlaybackStatus): void;
	/**
	 * The ID of the played content, is needed for the analytics team.
	 */
	rowId: number;
	/**
	 * set to `true` to enable the image zooming effect.
	 */
	enableZoomEffect?: boolean;
	sizes: string;
};

/**
 * Returns the class name corresponding to the given media playback status.
 *
 * @param {MediaContextPlaybackStatus} mediaPlaybackStatus - The media playback status.
 * @return {string} The class name corresponding to the media playback status.
 */
function getPlayStatusClassName(mediaPlaybackStatus: MediaContextPlaybackStatus) {
	const statusClassMap = {
		[MediaContextPlaybackStatus.PAUSED]: styles.paused,
		[MediaContextPlaybackStatus.STARTED]: styles.playing,
		[MediaContextPlaybackStatus.STOPPED]: styles.stopped,
	};
	return statusClassMap[mediaPlaybackStatus];
}

/**
 * Renders a media player component that can display audio or video content.
 *
 */
export function MediaAudioVideo({
	buttonVariant,
	copyright,
	duration,
	meta = '',
	preload = 'none',
	publicationDate,
	thumbnailAltText,
	thumbnailCopyright,
	thumbnailLink,
	thumbnailUrl,
	title,
	url,
	customTitle,
	showTitle,
	isPriority,
	isHero,
	onPlayStatusChange,
	enableZoomEffect = false,
	sizes,
	rowId,
}: Props) {
	const mediaRef = useRef<HTMLVideoElement>(null);

	const isAudio = buttonVariant === MediaControlButtonVariant.Audio;
	const isVideo = buttonVariant === MediaControlButtonVariant.Video;

	const { isPlaying, isPlayingOrPaused, isStopped, mediaPlaybackStatus, mediaPause, mediaStart, mediaStop } = useMediaState({
		isAudio,
		isVideo,
		shouldTrackPlay: true,
	});

	useEffect(() => {
		onPlayStatusChange?.(mediaPlaybackStatus);
	}, [mediaPlaybackStatus, onPlayStatusChange]);

	const structuredData = useMemo(() => {
		return buildMediaStructuredData({
			duration,
			publicationDate,
			thumbnail: thumbnailUrl ? resize(thumbnailUrl, 'landscape', 'xl') : undefined,
			title,
			type: buttonVariant === MediaControlButtonVariant.Audio ? 'audio' : 'video',
			url,
		});
	}, [buttonVariant, duration, publicationDate, thumbnailUrl, title, url]);

	const mediaTitle = useMemo(() => {
		return buildImageHoverTitle({
			altText: isPlayingOrPaused ? title : thumbnailAltText,
			copyright: isPlayingOrPaused ? copyright : thumbnailCopyright,
			variant: isPlayingOrPaused ? 'media' : 'bild',
		});
	}, [isPlayingOrPaused, title, thumbnailAltText, copyright, thumbnailCopyright]);

	const isMediaOffline = getIsMediaOffline(meta);

	const formattedDate = useMemo(() => {
		return publicationDate ? format(parseISO(publicationDate), "dd.MM.yyyy, HH:mm 'Uhr'", { locale: de }) : '';
	}, [publicationDate]);

	useEffect(() => {
		if (mediaRef.current && isPlaying) {
			mediaRef.current.play();
		}
	}, [isPlaying]);

	if (!url) {
		return null;
	}

	const playerStatusClass = getPlayStatusClassName(mediaPlaybackStatus);

	return (
		<AspectRatio as="figure" className={playerStatusClass}>
			<>
				{!isMediaOffline && structuredData}

				<MediaOverlay
					className={`${styles.overlay} ${isVideo ? styles.video : ''}`}
					buttonVariant={buttonVariant}
					isHero={!!isHero}
					showPlayButton={isStopped && !isMediaOffline}
					onPlayButtonClick={mediaStart}
					isPriority={isPriority}
					thumbnailAltText={thumbnailAltText}
					thumbnailCopyright={thumbnailCopyright}
					thumbnailLink={thumbnailLink}
					thumbnailTitle={mediaTitle}
					thumbnailUrl={thumbnailUrl}
					enableZoomEffect={enableZoomEffect}
					sizes={sizes}
				>
					{showTitle && (
						<div className={`body7 ${styles.titleRoot}`}>
							<div className="heading3">{customTitle}</div>
							{formattedDate && <div>{formattedDate}</div>}
						</div>
					)}
				</MediaOverlay>

				{!isMediaOffline && (
					<>
						{isVideo && isPlayingOrPaused && (
							<video
								className={`${styles.player}`}
								controls={true}
								controlsList="nodownload"
								onContextMenu={preventDefault}
								onEnded={mediaStop}
								onPause={mediaPause}
								playsInline={true}
								preload={preload}
								ref={mediaRef}
								src={url}
								title={mediaTitle}
								// used to identify the video for analytics
								data-t-moduleid={rowId}
							/>
						)}

						{isAudio && isPlayingOrPaused && (
							<audio
								className={`${styles.player}`}
								controls={true}
								controlsList="nodownload"
								onContextMenu={preventDefault}
								onEnded={mediaStop}
								onPause={mediaPause}
								playsInline={true}
								preload={preload}
								ref={mediaRef}
								src={url}
								title={mediaTitle}
								// used to identify the audio for analytics
								data-t-moduleid={rowId}
							/>
						)}
					</>
				)}
			</>
		</AspectRatio>
	);
}
